import React from "react";
import {
    FormikDateInput,
    FormikSelectInput,
    FormikTextInput,
} from "./FormikInputs";
import { FiltrosStyles } from "pages/Filtros.styles";
import { Button } from "components";
import HeaderButton from "components/Button/HeaderButton";
import FilterAltOffRoundedIcon from '@mui/icons-material/FilterAltOffRounded';
import FilterAltRoundedIcon from '@mui/icons-material/FilterAltRounded';

export default function Filters({ formik, inputsRender, children }) {
    const styles = FiltrosStyles();
    console.log(formik)
    const handleKeyDown = (e, name) => {
        if (e.key === 'Backspace' || e.key === 'Delete') {
            formik.setFieldValue(name, ''); // Vacía el campo cuando se presiona Delete
        }
    };
    const handleReset = () => {
        formik.resetForm();
    };

    return (
        <form className={styles.filtros} onSubmit={formik.handleSubmit}>
            <div style={{ display: "flex", flexWrap: "wrap", alignItems: "center" }}>
                {inputsRender.map((input, index) => (
                    <div key={index} className={styles.inputContainer}>
                        {input.type.toLowerCase() === "text" && (
                            <FormikTextInput
                                data={{
                                    [input.name]: {
                                        ...input
                                    }
                                }}
                                name={input.name}
                                formik={formik}
                                disabled={input.disabled}
                                className={styles.input}
                                onKeyDown={(e) => handleKeyDown(e, input.name)}
                            />
                        )}
                        {input.type.toLowerCase() === "select" && (
                            <FormikSelectInput
                                data={{
                                    [input.name]: {
                                        ...input
                                    }
                                }}
                                name={input.name}
                                formik={formik}
                                disabled={input.disabled}
                                options={input.options}
                                optionKey={input.firstOptionKey}
                                optionValue={input.firstOptionValue}
                                optionName={input.firstOptionName}
                                className={styles.input}
                            />
                        )}
                        {input.type.toLowerCase() === "date" && (
                            <FormikDateInput
                                type="date"
                                variant="standard"
                                data={{
                                    [input.name]: {
                                        ...input
                                    }
                                }}
                                name={input.name}
                                formik={formik}
                                disabled={input.disabled}
                                className={styles.input}
                            />
                        )}
                    </div>
                ))}
                <HeaderButton text={"Filtrar"} type="submit" >
                    <FilterAltRoundedIcon />
                </HeaderButton>
                <HeaderButton text={"Borrar filtros"} onClick={() => handleReset()} >
                    <FilterAltOffRoundedIcon />
                </HeaderButton>
                {/* <Button type="submit" text="Filtrar" className={styles.button} />
                <Button text="Borrar filtros" className={styles.button} onClick={handleReset} /> */}
            </div>
            {children}
        </form>
    );
};