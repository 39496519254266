
import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles(()=> ({
    containerInput: {
        '& .MuiFormControl-root':{
            '& .MuiFormLabel-root': {
                color: "#06132f",
                fontSize: "16px",
            },
            '& >div': {
                cursor: "pointer",
                display: "flex",
                '& section':{
                    display: "none",
                },
                '& :hover section': {
                    color: "#000",
                    display: "flex",
                    position: "absolute",
                    padding: "8px",
                    backgroundColor: "#eee",
                    zIndex: "2",
                    top: "0",
                    borderRadius: "10px",
                }
            },
            
            '& .MuiFormGroup-root': {
                display: "flex",
                flexDirection: "row",
                marginLeft: "15px",
                '& .MuiFormControlLabel-root': {
                    flexDirection: "row-reverse",
                    color: "#707070",
                    '& .MuiButtonBase-root': {
                        color: "#06132f"
                    }
                }
            }
        }
    },
    containerActividadFiscal: { 
        '& .MuiInputBase-root': {
            marginTop:"0px",    
            borderTop: "1px solid rgba(0, 0, 0, 0.42)",
            borderLeft: "1px solid rgba(0, 0, 0, 0.42)",
            borderRight:"1px solid rgba(0, 0, 0, 0.42)",
            borderRadius:"10px",
            '&, &::before': { 
                borderRadius:"10px",
            } 
        },
        '& .MuiFormControl-root': {
            '& label':{
                position:"sticky",
                color: "#06132f",
            },
            '& input': { 
               textAlign:"start",
                height: "90px"
            },

            '& input:focus': {
                 '&, &::after' :{
                     borderBottom:"1px solid #06132f !important", 
                     borderRight:"1px solid #06132f !important", 
                     borderTop:"1px solid #06132f !important", 
                     borderLeft: "1px solid #06132f !important",
                     borderRadius:"10px",
                     zIndex: "2"
                 },
            },
            '& legend': {
                display:"none"
            }
     }
    },
    containerRadio: {
        '& .MuiFormControl-root':{
            '& .MuiFormLabel-root': {
                color: "#06132f",
                fontSize: "12px",
            },}}
}))