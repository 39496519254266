import axiosPost from "./axiosPost";

export const MerchantBOBPUpd= async (credentials, data) => {
    const body = {
        service: "MerchantBOBPUpd",
        params: {
            merchantBeneficiarioId: data.merchantBeneficiarioId,
            merchantBeneficiarioNroDocumento: data.documento,
            merchantBeneficiarioEsDirecto: data.participacionIndirecta,
            merchantBeneficiarioParticipacion: data.porcentaje,
            merchantBeneficiarioVersion: data.merchantBeneficiarioVersion
        },
    };    
    try {
        
        const response = await axiosPost("Y", body, credentials)
        return response;
    } catch (err) {
        console.error(`Error: ${err}`);
    }
}