import React, { useEffect, useState } from 'react'
import XGrid from 'components/UI/Grid/XGrid'
import { apoderados as titles } from 'consts/titulos-de-tablas'
import { Box, Grid } from '@mui/material';
import { MerchantBOFilesGet } from 'services/hooli-services/MerchantBOFilesGet';
import { useRecoilValue } from 'recoil';
import { rowSelected, userCredentials } from 'recoilState/GlobalState';
import { v4 } from 'uuid';
import { Button, HeaderButton } from 'components';
import { CloudDownload } from '@mui/icons-material';
import { MerchantBOFilesDo } from 'services/hooli-services/MerchantBoFilesDo';
import axios from 'axios';
import { DropZone } from 'components/DropZone';
import { DocumentosPut } from 'services/hooli-services/DocumentosPut';
import { MerchantBOAPUpd } from 'services/hooli-services/MerchantBOAPUpd';
function Apoderados({ merchantInfo, load, setLoad, setModalDetails, modalDetails, location }) {

    const [rows, setRows] = useState([]);
    const credentials = useRecoilValue(userCredentials);
    const xgridRowSelected = useRecoilValue(rowSelected);
    const [showDropZone, setShowDropZone] = useState(false);
    const [file, setFile] = useState();
    useEffect(() => {

        getDocuments();
    }, []);
    console.log("Info merchant", xgridRowSelected)
    const getDocuments = async () => {
        const data = {
            code: "APO",
            cuit: merchantInfo.merchantCUIT.replaceAll('-', '')
        }
        const response = await MerchantBOFilesGet(credentials, data);
        console.log("Esto es response", response);
        const mapResponse = response.result[0].archivos.map((e) => {
            return {
                id: v4(),
                [titles[0]]: e
            }
        });
        setRows(mapResponse);
    }

    const handleDownload = async () => {
        const data = {
            fileName: xgridRowSelected.row["Documentos"],
            code: "APO",
            cuit: merchantInfo.merchantCUIT.replaceAll('-', ''),
            action: "R"
        }
        const response = await MerchantBOFilesDo(credentials, data)
        if (response.status.code === 1 && response.result[0].url) {
            const { data } = await axios.get(response.result[0].url, {
                headers: {
                    'Accept': 'application/pdf',
                    "Content-Type": "application/octet-stream"
                },
                responseType: "blob",
                data: {}
            })
            let urlBlob = window.URL.createObjectURL(new Blob([data]));
            let a = document.createElement('a');
            a.href = urlBlob;
            a.download = `${xgridRowSelected.row["Documentos"].replaceAll(".pdf", " ")}.pdf`; // Nombre del archivo
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
        }
    }
    const handleUpdFile = async () => {
        const data = {
            fileName: `${file[0]?.path.replaceAll(".pdf", "")}_APO.pdf`,
            code: "APO",
            cuit: merchantInfo.merchantCUIT.replaceAll('-', ''),
            action: "U"
        }
        const response = await MerchantBOFilesDo(credentials, data)
        if (response.status.code === 1) {
            // console.log("File updated", file[0])
            await DocumentosPut(response.result[0].url, file[0])

            setFile(null);
            setShowDropZone(false);
            getDocuments();
        }
    }

    const handleClickUpd = async (estadoCod) => {
        setLoad(true);
        const data = {
            merchantId: merchantInfo.merchantId,
            estadoRegistroCod: estadoCod,
        }
        const response = await MerchantBOAPUpd(credentials, data, location);
        setLoad(false);
        setModalDetails({ ...modalDetails, isOpen: false })
    }
    return (
        <>
            {
                showDropZone ? (
                    <div style={{ display: "flex", flexDirection: "column", gap: "8px", alignItems: "center" }}>
                        <DropZone file={file} setFile={setFile} />
                        <Grid item display={"flex"} flexDirection={"row"} justifyContent={"center"} alignItems={"center"} width={"100%"}>
                            <Button text={"Subir archivo"} onClick={() => handleUpdFile()} />
                            <Button text={"Cancelar"} onClick={() => setShowDropZone(false)} />
                        </Grid>
                    </div>
                ) : (
                    <Grid container height={"500px"}>
                        <Grid item md={8} lg={8} height={"300px"} >
                            <Box width={"100%"} padding={"4px"} display={"flex"} justifyContent={"flex-end"} alignItems={"center"}>
                                <HeaderButton
                                    text={"Descargar archivo"}
                                    onClick={() => handleDownload()}
                                    disabled={xgridRowSelected.status !== true}
                                >
                                    <CloudDownload />
                                </HeaderButton>
                            </Box>
                            <XGrid
                                titles={titles}
                                rows={rows}
                            />
                        </Grid>
                        <Grid md={4} lg={4} display={"flex"} justifyContent={"center"} alignItems={"center"}>
                            <Button text={"Subir archivo"} onClick={() => setShowDropZone(true)} />
                        </Grid>
                        <Box display={"flex"} justifyContent={"center"} alignItems={"center"} width={"100%"}>
                            <Button text={"Confirmar"} onClick={() => handleClickUpd("A")} disabled={load} />
                            <Button text={"Rechazar"} onClick={() => handleClickUpd("R")} disabled={load} />
                            <Button text={"Cerrar"} onClick={() => setModalDetails({ ...modalDetails, confirmationModalOpen: true })} />
                        </Box>

                    </Grid>
                )
            }
        </>
    )
}

export default Apoderados
