import React, { useEffect } from "react";

import { Box, Fade, Modal } from "@mui/material";
import { Typography } from "@mui/material";

import close from "../../../assets/icons/borrar.svg";

// recoil
import { useRecoilState, useRecoilValue } from "recoil";
import { modalData, secondaryModalData } from "../../../recoilState/GlobalState";

import { ModalStyles } from "./Modal.styles";
import FormButton from "../Button/FormButton";

export default function TransitionModal({
    procede,
    children,
    title,
    confirmationModal = false,
    customOnClose = () => { },
}) {
    const [modalState, setModalDetails] = useRecoilState(modalData);

    const styles = ModalStyles();

    useEffect(() => {
        const handleKeyUp = ({ key }) => {
            if (key === "Escape") {
                setModalDetails({ ...modalState, confirmationModalOpen: true });
            }
        };

        modalState.isOpen && window.addEventListener("keyup", handleKeyUp);

        return () => {
            window.removeEventListener("keyup", handleKeyUp);
        };
    }, [modalState.isOpen]);

    const handleCloseModal = () => {
        if (confirmationModal) {
            setModalDetails({
                ...modalState,
                confirmationModalOpen: true,
            });
            customOnClose();
            return;
        }

        setModalDetails({ isOpen: false });
        customOnClose();
    };


    return (
        <>
            <Modal
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
                className={styles.modal}
                open={modalState.isOpen}
            >
                <Fade in={modalState.isOpen} className={styles.modal}>
                    <div className={styles.modal}>
                        <div className={styles.paper}>
                            <Box width={"100%"} display={"flex"} justifyContent={"center"} alignItems={"center"}>
                                <Typography
                                    variant="h6"
                                    fontWeight={100}
                                    className={styles.title}

                                    style={{
                                        color:
                                            procede === "cancelar" ||
                                                modalState.title
                                                    ?.toLowerCase()
                                                    .includes("eliminar") ||
                                                title
                                                    ?.toLowerCase()
                                                    .includes("eliminar")
                                                ? "#f00"
                                                : "",
                                    }}
                                >
                                    {modalState ? modalState.title : title}

                                </Typography>
                            </Box>

                            <hr className={styles.hr} />
                            <img
                                alt="close"
                                src={close}
                                className={styles.closeButton}
                                role="button"
                                onClick={handleCloseModal}
                            />
                            {children}
                        </div>
                    </div>
                </Fade>
            </Modal>

            {confirmationModal && modalState.confirmationModalOpen && (
                <Modal
                    aria-labelledby="simple-modal-title"
                    aria-describedby="simple-modal-description"
                    className={styles.confirmationModal}
                    open={modalState.isOpen}
                >
                    <form
                        onSubmit={(e) => {
                            e.preventDefault();
                            setModalDetails({
                                isOpen: false,
                                confirmationModalOpen: false,
                            });
                        }}
                    >
                        <Fade in={modalState.isOpen} className={styles.modal}>
                            <div className={styles.confirmationModal}>
                                <div className={styles.paper}>
                                    ¿Estás seguro que deseás cancelar?
                                    <br />
                                    Perderás todos los cambios establecidos
                                    <FormButton
                                        closeButtonText={"No"}
                                        isConfirmationModal={true}
                                        newFormData={{ button: "Sí" }}
                                    />
                                </div>
                            </div>
                        </Fade>
                    </form>
                </Modal>
            )}
        </>
    );
}