import axiosPost from "./axiosPost";

export const MerchantBOIdentidadUpd= async (credentials, data, location) => {
    const body = {
        service: "MerchantBOIdentidadUpd",
        params: {
            merchantId: data.merchantId,
            tipoSocietarioId: data.tipoSocietarioId,
            cargoId: data.cargoId,
            rubroMerchantId: data.rubroDeMerchantId,
            estadoDeRegistroCod: data.estadoRegistroCod,
            registroLatitud: location.latitud,
            registroLongitud: location.longitud,
        },
    };    
    try {
        
        const response = await axiosPost("Y", body, credentials)
        return response;
    } catch (err) {
        console.error(`Error: ${err}`);
    }
}