import axiosPost from "./axiosPost";

export const RubroDeMerchantGet= async (credentials, data) => {
    const body = {
        service: "RubroDeMerchantGet",
        params: {
            rubroMerchantId: data.rubroMerchantId,
            rubroMerchantCod: data.rubroMerchantCod,
            rubroMerchantEsAgencia: data.rubroMerchantEsAgencia,
            rubroMerchantADomicilio: data.rubroMerchantADomicilio,
            rubroDeMerchantEsVISA: data.rubroDeMerchantEsVISA,
        },
    };    
    try {
        
        const response = await axiosPost("Y", body, credentials)
        return response;
    } catch (err) {
        console.error(`Error: ${err}`);
    }
}