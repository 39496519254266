import React, { useEffect, useState } from "react";
import { Routes as Switch, Route, useLocation, useNavigate } from "react-router-dom";

import { useRecoilState, useSetRecoilState } from "recoil";
import { userInfo, userCredentials, snackbarData } from "./recoilState/GlobalState";

import { getUserCredentials } from "services/getUserCredentials";
import { checkAuth, checkAuthCredentials } from "services/checkAuth";
import { usuarioMinimosGet } from "services/services-mia";

import ProtectedRoute from "./components/route/ProtectedRoute";
import { CircularIndeterminate, Header } from "components";
import SnackBar from "components/utils/snackbars/Snackbar";

import {
    Dashboard,
} from "./pages";

import { Routes as ROUTES } from "./consts/Routes-App";

import { merchantLogoGet } from "services/services-mia/merchant";


import s from "./App.module.scss"
import Comercios from "pages/comercios";
import { RegistroComercio } from "pages/comercios/registros";
import { subscribe, unsubscribe } from "components/utils/eventManager";

export default function App() {
    const [loading, setLoading] = useState(true);
    const [isAuth, setIsAuth] = useState(false);
    const [credentials, setCredentials] = useRecoilState(userCredentials);
    const setInfo = useSetRecoilState(userInfo);
    const [{ message, severity, open }, setSnackbarInfo] = useRecoilState(snackbarData);
    const navigate = useNavigate()

    useEffect(() => {
        setLoading(true);
        (async () => {
            const newCredentials = checkAuthCredentials();
            // const newCredentials = await getUserCredentials();
            const coords = navigator.geolocation.getCurrentPosition(
                (data) => {
                    console.log(data.coords.latitude.toFixed(6), data.coords.longitude.toFixed(6))
                    sessionStorage.setItem("latitud", data.coords.latitude.toFixed(6));
                    sessionStorage.setItem("longitud", data.coords.longitude.toFixed(6));
                }
            );
            console.log("Nuevas credenciales", newCredentials);
            if (newCredentials) {
                setCredentials(newCredentials);
                const userData = await usuarioMinimosGet(newCredentials);
                const { result: merchantData } = await merchantLogoGet({}, newCredentials);
                setInfo({ ...merchantData[0], ...userData });
            }
            setIsAuth(checkAuth());
            setLoading(false);
        })();
    }, [setCredentials, setInfo]);
    useEffect(() => {
        const handleNotFile = (data) => {
            setSnackbarInfo({
                message: data.message,
                severity: "warning",
                open: true,
            });
            if (data.route) {
                navigate(data.route)
            }
        }
        subscribe("NotFile", handleNotFile);
        return () => {
            unsubscribe("NotFile");
        }
    }, []);



    if (loading) return <CircularIndeterminate />;

    return (
        <div className={s.container}>
            <div className={setIsAuth.header}>
                <Header />

            </div>
            <div className={s.body}>
                <SnackBar />

                <Switch>


                    <Route
                        element={
                            <ProtectedRoute
                                isAuth={isAuth}
                            />
                        }
                    >
                        <Route
                            path={ROUTES.dashboard.route}
                            exact
                            element={<Dashboard route={ROUTES.dashboard} />}
                        />
                        <Route
                            path={ROUTES.comercios.route}
                            exact
                            element={<Comercios />}
                        />
                        <Route
                            path={ROUTES.registrosComercios.route}
                            exact
                            element={<RegistroComercio />}
                        />


                        <Route
                            path="/*"
                            element={<Dashboard />}
                        />
                    </Route>
                </Switch>
            </div>
        </div>
    );
}
