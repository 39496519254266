import axiosPost from "./axiosPost";

export const MerchantBOFilesGet= async (credentials, data) => {
    const body = {
        service: "MerchantBOFilesGet",
        params: {
            code: data.code,
            cuit: data.cuit,
            beneficiarioNroDeDocumento: data.beneficiarioNroDeDocumento,
        },
    };    
    try {
        
        const response = await axiosPost("Y", body, credentials)
        return response;
    } catch (err) {
        console.error(`Error: ${err}`);
    }
}