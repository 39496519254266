import axiosPost from "./axiosPost";

export const MerchantBOBPGet= async (credentials, merchantId) => {
    const body = {
        service: "MerchantBOBPGet",
        params: {
            merchantId: merchantId
        },
    };    
    try {
        
        const response = await axiosPost("Y", body, credentials)
        return response;
    } catch (err) {
        console.error(`Error: ${err}`);
    }
}