import React, { useEffect, useState } from 'react'
import XGridDemo from "components/UI/Grid/XGrid";
import { MerchantBOBPGet } from 'services/hooli-services/MerchantBOBPGet';
import { userCredentials } from 'recoilState/GlobalState';
import { useRecoilValue } from 'recoil';
import { Box, Grid } from '@mui/material';
import { Button, Buttons, HeaderButton } from 'components';
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import EditRoundedIcon from '@mui/icons-material/EditRounded';
import ClearRoundedIcon from '@mui/icons-material/ClearRounded';
import { MerchantBOFilesGet } from 'services/hooli-services/MerchantBOFilesGet';
import { MerchantBOFilesDo } from 'services/hooli-services/MerchantBoFilesDo';
import axios from 'axios';
import { FormBen } from './FormBen';
import { DropZone } from 'components/DropZone';
import { DocumentosPut } from 'services/hooli-services/DocumentosPut';
import { MerchantBOBPDel } from 'services/hooli-services/MerchantBOBPDel';
import { MerchantBOBeneficiariosUpd } from 'services/hooli-services/MerchantBOBeneficiariosUpd';

const titles = [
    "DNI",
    "Participacion",
    "Indirecto",
];

export const BenefeiciariosPrincipales = ({ merchantInfo, load, setLoad, setModalDetails, modalDetails, location }) => {
    const credentials = useRecoilValue(userCredentials);
    const [rows, setRows] = useState([]);
    const [actions, setActions] = useState(false);
    const [beneficiario, setBeneficiario] = useState({});
    const [archivos, setArchivos] = useState([]);
    const [selected, setSelected] = useState({ status: false, row: {} });
    const [file, setFile] = useState(null)
    let BPDataGet = async () => {
        const response = await MerchantBOBPGet(credentials, merchantInfo.merchantId);
        const rowsModified = response.result.map((row) => {
            return {
                id: row.merchantBeneficiarioId,
                DNI: row.merchantBeneficiarioNroDocumento,
                Participacion: row.merchantBeneficiarioParticipacion,
                Indirecto: row.merchantBeneficiarioEsDirecto,
                Version: row.merchantBeneficiarioVersion,
            }
        })
        setRows(rowsModified);
        // console.log(response);
    }
    const getDocument = async () => {
        const response = await MerchantBOFilesGet(credentials, { code: "BEN", cuit: merchantInfo.merchantCUIT.replaceAll("-", ""), beneficiarioNroDeDocumento: selected.row.DNI })
        console.log(response)
        setArchivos(response.result[0].archivos);
    }
    const handleReadFile = async (item) => {
        const data = {
            code: "BEN",
            cuit: merchantInfo.merchantCUIT.replaceAll('-', ''),
            beneficiarioNroDeDocumento: selected.row.DNI,
            action: "R",
            fileName: item
        }
        const response = await MerchantBOFilesDo(credentials, data)
        if (response.status.code === 1 && response.result[0].url) {
            const { data } = await axios.get(response.result[0].url, {
                headers: {
                    'Accept': 'application/octet-stream',
                    "Content-Type": "application/octet-stream"
                },
                responseType: "blob",
                data: {}
            })
            let urlBlob = window.URL.createObjectURL(new Blob([data]));
            let a = document.createElement('a');
            a.href = urlBlob;
            a.download = item; // Nombre del archivo
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
        }
    }
    const handleUpdFile = async () => {
        const data = {
            fileName: file[0].name,
            code: "BEN",
            cuit: merchantInfo.merchantCUIT.replaceAll('-', ''),
            beneficiarioNroDeDocumento: selected.row.DNI,
            action: "U"
        }
        const response = await MerchantBOFilesDo(credentials, data)
        if (response.status.code === 1) {
            // console.log("File updated", file[0])
            await DocumentosPut(response.result[0].url, file[0])
            setSelected({ status: false, row: {} })
            setFile(null)
            setActions(false)
        }
    }
    const handleAction = (param) => {
        if (param === "delete") {
            MerchantBOBPDel(credentials, { merchantBeneficiarioId: selected.row.id })
            setSelected({ status: false, row: {} })
        } else {
            setActions(param)
        }
    }
    const deleteAction = () => {
        setActions(false)
    }
    const handleUpd = async (values) => {
        setLoad(true)
        const response = await MerchantBOBeneficiariosUpd(credentials, {
            merchantId: merchantInfo.merchantId,
            estadoRegistroCod: "A"
        }, location)
        setLoad(false)
        setModalDetails({ ...modalDetails, isOpen: false })
    }
    const handleDecline = async () => {
        setLoad(true)
        const response = await MerchantBOBeneficiariosUpd(credentials, {
            merchantId: merchantInfo.merchantId,
            estadoRegistroCod: "R"
        }, location)
        setLoad(false)
        setModalDetails({ ...modalDetails, isOpen: false })
    }
    useEffect(() => {
        if (selected.status === true) {
            getDocument()
        }
        if (selected.status === false) {
            setArchivos([])
        }
        BPDataGet();
    }, [selected]);
    console.log(selected, merchantInfo)
    return (
        <>
            {actions === false ? <><div style={{ height: "400px", display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                <div style={{ width: "60%", height: "100%", display: "flex", flexDirection: "column", alignItems: "end" }}>
                    <Buttons filtersApplied={true}>
                        <HeaderButton
                            disabled={selected.status !== true}
                            text={"Editar"}
                            onClick={() => handleAction("edit")}
                        >
                            <EditRoundedIcon />
                        </HeaderButton>
                        <HeaderButton
                            disabled={selected.status !== true}
                            text={"Eliminar"}
                            onClick={() => handleAction("delete")}
                        >
                            <ClearRoundedIcon />
                        </HeaderButton>
                        <HeaderButton
                            disabled={selected.status === true}
                            text={"Nuevo"}
                            onClick={() => handleAction("new")}
                        >
                            <AddRoundedIcon />
                        </HeaderButton>
                    </Buttons>
                    <Box width={"100%"} height={"80%"}>
                        <XGridDemo
                            titles={titles}
                            rows={rows}
                            secondarySelected={selected}
                            setSecondarySelected={setSelected}
                        />
                    </Box>
                </div>
                <Box width={"30%"} height={"100%"} display={"flex"} alignItems={"center"}>
                    <div style={{ display: "flex", flexDirection: "column", height: "100%", width: "100%", gap: "8px", justifyContent: "space-between" }}>
                        <div>
                            <h3>Documentos</h3>
                            <div style={{ display: "flex", flexDirection: "column", gap: "8px" }}>
                                {archivos.length > 0 && archivos.map((item, i) => (
                                    <div key={i} style={{ border: "1px solid grey", padding: "4px", borderRadius: "5px", cursor: "pointer" }} onClick={() => handleReadFile(item)}>
                                        {item}
                                    </div>
                                ))}
                            </div>
                        </div>
                        <Box display={"flex"} alignSelf={"end"}>
                            <Button text={"Subir"} disabled={selected.status !== true} onClick={() => handleAction("upd")} />
                        </Box>
                    </div>
                </Box>
            </div>
                <div>
                    <Button text={"Confirmar"} onClick={() => handleUpd()} disabled={load} />
                    <Button text={"Rechazar"} onClick={() => handleDecline()} disabled={load} />
                    <Button text={"Cerrar"} onClick={() => setModalDetails({ ...modalDetails, confirmationModalOpen: true })} />
                </div ></> : actions === "upd" ? <div style={{ display: "flex", flexDirection: "column", gap: "8px", alignItems: "center" }}>
                    <DropZone file={file} setFile={setFile} />
                    <Grid item display={"flex"} flexDirection={"row"} justifyContent={"center"} alignItems={"center"} width={"100%"}>
                        <Button text={"Subir"} onClick={() => handleUpdFile()} />
                        <Button text={"Cancelar"} onClick={() => deleteAction()} />
                    </Grid>
                </div> : <FormBen data={selected} setRow={setSelected} merchantId={merchantInfo.merchantId} onClose={deleteAction} actions={actions} credentials={credentials} />}
        </>
    )
}
