import axiosPost from "./axiosPost";

export const MerchantBOAsignacionIns = async (credentials, data) => {
    const { merchantId, status } = data;
    const body = {
        service: "MerchantBOAsignacionIns",
        params: {
            merchantId,
            merchantBoAsignacionEstado: status,
        },
    };
    try {
        const response = await axiosPost("Y", body, credentials);
        return response;
    } catch (err) {
        console.error(`Error: ${err}`);
    }
};
