export const Routes = {
    login: {
        route: "/login",
    },
    dashboard: {
        route: "/",
        wordKey: "Home",
        showMenuItem: true,
        needSuperAuth: false,
    },
    comercios: {
        route: "/comercios",
        wordKey: "Home",
        showMenuItem: true,
        needSuperAuth: false,
    },
    registrosComercios: {
        route: "/comercios-registros",
        wordKey: "Home",
        showMenuItem: false,
        needSuperAuth: false,
    },
};
