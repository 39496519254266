import { FALLBACK_URL } from "consts/env";

export const checkAuthCredentials = () => {
    if (
        sessionStorage.getItem("hooliToken") &&
        sessionStorage.getItem("sessionId")
    ) {
        return {
            hooliToken: sessionStorage.getItem("hooliToken"),
            sessionId: sessionStorage.getItem("sessionId"),
        };
    }

    const data = new URLSearchParams(window.location.search);

    const sessionId = data.get("session");
    const hooliToken = data.get("token");
    const latitud = data.get("latitud");
    const longitud = data.get("longitud");
    if (hooliToken && sessionId && latitud && longitud) {
        sessionStorage.setItem("sessionId", sessionId);
        sessionStorage.setItem("hooliToken", hooliToken);
        sessionStorage.setItem("latitud", latitud);
        sessionStorage.setItem("longitud", longitud);

        return {
            hooliToken: hooliToken,
            sessionId: sessionId,
        };
    } else {
        window.location.assign(FALLBACK_URL);
    }
};

export const checkAuth = () => {
    return sessionStorage.getItem("hooliToken") &&
        sessionStorage.getItem("sessionId")
        ? true
        : false;
};
