import axiosPost from "./axiosPost";

export const UsuarioBOGet = async (credentials) => {
    console.log("Credenciales", credentials);
    const body = {
        service: "UsuarioBOGet",
        params: {
            rolCod: "OCM",
        },
    };

    const result = axiosPost("Y", body, credentials);
    return result;
};
