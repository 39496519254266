import axiosPost from "./axiosPost";

export const MerchantBOLAFTUpd= async (credentials, data, location) => {
    const body = {
        service: "MerchantBOLAFTUpd",
        params: {
            merchantId: data.merchantId,
            merchantSO: data.merchantSO,
            merchantPEP: data.merchantPEP,
            merchantNoResidente: data.merchantNoResidente,
            paisId: data.paisId,
            merchantNoResidebteTC: data.merchantNoResidebteTC,
            merchantNoResidenteCuenta: data.merchantNoResidenteCuenta,
            estadoRegistroCod: data.estadoRegistroCod,
            registroLatitud: location.latitud,
            registroLongitud: location.longitud,
        },
    };    
    try {
        
        const response = await axiosPost("Y", body, credentials)
        return response;
    } catch (err) {
        console.error(`Error: ${err}`);
    }
}