import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles({
    inputRazonSocial: {
        "& .MuiFormControl-root": {
            "& label": {
                color: "#9373B1",
            },

            "& input:focus": {
                "&, &::after": {
                    borderBottom: "2px solid #9373B1 !important",
                    zIndex: "2",
                },
            },
        },
    },
    containerInput: {
        "& .MuiBox-root": {
            width: "50%",
            "& .MuiFormControl-root": {
                "& label": {
                    color: "#9373B1",
                },

                "& input:focus": {
                    "&, &::after": {
                        borderBottom: "2px solid #9373B1 !important",
                        zIndex: "2",
                    },
                },
            },
        },
    },
});
