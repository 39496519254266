import axiosPost from "./axiosPost";

export const MerchantBONotaGet= async (credentials, merchantId) => {
    const body = {
        service: "MerchantBONotaGet",
        params: {
            merchantId: merchantId,
        },
    };    
    try {
        
        const response = await axiosPost("Y", body, credentials)
        return response;
    } catch (err) {
        console.error(`Error: ${err}`);
    }
}