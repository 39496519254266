import { Box, Checkbox, Grid } from '@mui/material';
import { Button } from 'components'
import { useFormik } from 'formik';
import styles from "./Beneficiarios.module.scss"
import React from 'react'
import * as yup from "yup";
import { FormikTextInput } from 'components/Filters/FormikInputs';
import { MerchantBOBPUpd } from 'services/hooli-services/MerchantBOBPUpd';
import { MerchantBOBPIns } from 'services/hooli-services/MerchantBOBPIns';

export const FormBen = ({ data, setRow, onClose, merchantId, actions, credentials }) => {
    console.log(data)
    let patternTwoDigisAfterComma = /^\d+(\.\d{0,2})?$/;
    const validationSchema = yup.object({
        documento: yup.number().min(10000, "Debe tener mas de 5 digitos").max(999999999, "Debe tener menos de 9 digitos").required("Debes ingresar su numero de documento"),
        porcentaje: yup.number("Debe ser un numero").test(
            "is-decimal",
            "Debe ingresar maximo dos decimales",
            (val) => {
                if (val != undefined) {
                    return patternTwoDigisAfterComma.test(val);
                }
                return true;
            }
        ).max(100, "Debe ser un valor menor o igual a 100%").min(10, "Debe ser un valor mayor a 10%").required("Debes ingresar un porcentaje"),
    });
    const formik = useFormik({
        initialValues: {
            documento: data.row.DNI || "",
            porcentaje: data.row.Participacion || "",
            participacionIndirecta: data.row.Indirecto === "Y" ? true : false
        },
        validationSchema: validationSchema,
        initialErrors: {
            documento: false,
            porcentaje: false,
            participacionIndirecta: false
        }
    });
    const dataFormik = {
        documento: { label: "Nro de documento*", placeholder: "22222222" },
        porcentaje: { label: "Porcentaje de participación*", placeholder: "10%" },
    }
    const handleChangeParticipacionDirecta = (e) => {
        formik.setFieldValue('participacionIndirecta', e.target.checked)
    }
    console.log(actions)
    const handleUpd = async () => {
        if (actions === "edit") {
            console.log(data.row)
            await MerchantBOBPUpd(credentials, {
                merchantBeneficiarioId: data.row.id,
                documento: formik.values.documento,
                participacionIndirecta: formik.values.participacionIndirecta === true ? "Y" : "N",
                porcentaje: formik.values.porcentaje,
                merchantBeneficiarioVersion: data.row.Version
            })
            setRow({ status: false, row: {} })
        }
        if (actions === "new") {
            await MerchantBOBPIns(credentials, {
                merchantId: merchantId,
                documento: formik.values.documento,
                participacionIndirecta: formik.values.participacionIndirecta === true ? "Y" : "N",
                porcentaje: formik.values.porcentaje,
            })
            setRow({ status: false, row: {} })
        }
        onClose()
    }
    return (
        <>
            <div>
                <Grid width={"100%"} display={"flex"} gap={4} padding={"20px 0 0"} alignItems={"center"}>
                    <Box display={"flex"} width={"100%"} gap={4} className={styles.containerInput}>
                        <FormikTextInput data={dataFormik} name={"documento"} formik={formik} className={styles.input} />
                    </Box>
                    <Box display={"flex"} width={"100%"} gap={4} className={styles.containerInput}>
                        <FormikTextInput data={dataFormik} name={"porcentaje"} formik={formik} className={styles.input} />
                    </Box>
                    <Box display={"flex"} width={"100%"} gap={4} marginTop={"16px"} marginBottom={"8px"} className={styles.containerInput}>
                        <Box display={"flex"} flexDirection={"column"} alignItems={"start"} justifyContent={"center"}>
                            <p style={{ color: "rgba(0, 0, 0, 0.6)", fontSize: "12px", fontWeight: "600" }}>Participación indirecta</p>
                            <Checkbox
                                checked={formik.values.participacionIndirecta}
                                onChange={handleChangeParticipacionDirecta}
                            />
                        </Box>
                    </Box>
                </Grid>
            </div>
            <div>
                <Button text={"Aceptar"} onClick={() => handleUpd()} />
                <Button text={"Cancelar"} onClick={() => onClose()} />
            </div >
        </>
    )
}
