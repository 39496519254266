import axios from "axios";
import { fallbackURL } from "../../consts/FallbackURL";
import { serviceURLHooli } from "../../consts/ServiceURL";
import { emit } from "components/utils/eventManager";
// import { emit } from "../../utils/eventManager";

const axiosPost = async (loginReq, body, arg) => {
    const headers = {
        "Content-Type": "application/json",
    };

    if (loginReq === "Y") {
        headers["x-hooli-token"] = arg.hooliToken;
        headers["x-session-id"] = arg.sessionId;
    }


    try {
        const response = await axios.post(serviceURLHooli, body, { headers });
        const result = response.data;

        if (!result || result === null || result === undefined) {
            return {
                status: {
                    code: 0,
                    errcode: 99999,
                    errmsg: "Error inesperado en request",
                    action: "U",
                    id: null,
                },
                result: [],
            };
        }

        if (
            result.status.errmsg ===
            "Su sesión ha expirado por falta de actividad"
        ) {
            localStorage.clear();
            // window.location.assign(fallbackURL);
        }

        return result;
    } catch (err) {
        return {
            status: {
                code: 0,
                errcode: err.response ? err.response.status : 0,
                errmsg: err.message,
                action: "U",
                id: null,
            },
            result: [],
        };
    }
};

axios.interceptors.response.use(
    (response) => {
        // console.log(
        //     "Estos son los interceptors",
        //     response,
        //     window.sessionStorage.getItem("hooliToken")
        // );

        if (response.data?.status?.code != 1) {
            switch (response.data?.status?.action) {
                case "X": {
                    if (response.data?.status?.errcode === 10000) {
                        window.sessionStorage.setItem("hooliToken", "");
                        window.sessionStorage.setItem("sessionId", "");
                        return emit("NotFile", {
                            route: "/login",
                            message: response.data.status.errmsg,
                        });
                    }
                    if (response.data?.status?.errcode === 55004) {
                        return emit("NotFile", {
                            message: "El archivo no existe",
                        });
                    }
                    return response;
                }
                case "W": {
                    if (response.data?.status?.errcode === 10000) {
                        window.sessionStorage.setItem("hooliToken", "");
                        window.sessionStorage.setItem("sessionId", "");
                        return emit("NotFile", {
                            route: "/login",
                            message: response.data.status.errmsg,
                        });
                    }
                    if (response.data?.status?.errcode === 55004) {
                        return emit("NotFile", {
                            message: "El archivo no existe",
                        });
                    }
                    return emit("NotFile", {
                        message: response.data.status.errmsg,
                    });
                }
                case "E": {
                    if (response.data?.status?.errcode === 10000) {
                        window.sessionStorage.setItem("hooliToken", "");
                        window.sessionStorage.setItem("sessionId", "");
                        return emit("NotFile", {
                            route: "/login",
                            message: response.data.status.errmsg,
                        });
                    }
                    if (response.data?.status?.errcode === 55004) {
                        return emit("NotFile", {
                            message: "El archivo no existe",
                        });
                    }
                    return emit("NotFile", {
                        message: response.data.status.errmsg,
                    });
                }
                default: {
                    return response;
                }
            }
        }

        return response;
    },
    (error) => {}
);

export default axiosPost;
