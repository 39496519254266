import axiosPost from "./axiosPost";

export const MerchantOrigenGet = async (credentials) => {
    const body = {
        service: "MerchantOrigenGet",
        params: {},
    };

    const result = axiosPost("Y", body, credentials);
    return result;
};
