import axiosPost from "./axiosPost";

export const MerchantBOGet = async (credentials, data) => {
    const {
        merchantCUIT,
        merchantNombre,
        fechaDesde,
        fechaHasta,
        merchantStatusOperativo,
        merchantStatusRegistro,
        merchantBoAsignacionUsuarioId,
        merchantOrigenCod,
    } = data;
    console.log("Credenciales", credentials);
    const body = {
        service: "MerchantBOGet",
        params: {
            merchantCUIT,
            merchantNombre,
            fechaDesde,
            fechaHasta,
            merchantStatusOperativo,
            merchantStatusRegistro,
            merchantBoAsignacionUsuarioId,
            merchantOrigenCod,
        },
    };

    const result = axiosPost("Y", body, credentials);
    return result;
};
