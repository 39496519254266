import React from "react";
import { Box, Typography } from "@mui/material";
import { AngleBracket } from "assets/icons/index.js";
import { useStyles } from "./styles.js";
import { Identification } from "assets/icons"
import HourglassBottomRoundedIcon from '@mui/icons-material/HourglassBottomRounded';
import CheckRoundedIcon from '@mui/icons-material/CheckRounded';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import PriorityHighRoundedIcon from '@mui/icons-material/PriorityHighRounded';
import style from "./index.module.scss"


export const Card = ({ image, title, subtitle, user, opacity, handleClick, merchantId, status }) => {
    console.log(user)
    const styles = useStyles();
    const onClick = () => {
        if (typeof handleClick === "function") {
            const data = {
                merchantId,
                title: title,
            }
            return handleClick(data)
        } else {
            return
        }
    }
    return (
        <Box display={"flex"} sx={{ cursor: "pointer" }} height={"60px"} width={"600px"} justifyContent={"center"} border={"2px"} boxShadow={"0px 1px 5px 0px rgba(153,153,153,0.7)"} borderRadius={"10px"} onClick={onClick} className={styles.root}  >

            <Box display="flex" alignItems={"center"} marginRight={2} marginLeft={2} width={"50px"}>
                <img src={image || Identification} alt="logo" className={styles.imgComerciosDefault} />
            </Box>

            <Box display={"flex"} flexDirection={"column"} justifyContent={"center"} width={"60%"}>
                <Typography variant="h1" className={styles.title}>{title}</Typography>
                <Typography variant="subtitle1" className={styles.subtitle}>{subtitle}</Typography>
            </Box>
            <Box display="flex" flexDirection={"column"} alignItems={"end"} justifyContent={"center"} marginLeft={0} marginRight={2}>
                <Typography variant="subtitle1" className={styles.subtitle}>{user}</Typography>
                {status === "A" ?
                    <div className={`${style.icon} ${style.check}`}>
                        <CheckRoundedIcon />
                    </div> :
                    status === "R" ?
                        <div className={`${style.icon} ${style.cancel}`}>
                            <CloseRoundedIcon />
                        </div> :
                        status === "E" ?
                            <div className={`${style.icon} ${style.hourglass}`}>
                                <HourglassBottomRoundedIcon />
                            </div> :
                            <div className={`${style.icon}`}>
                                <PriorityHighRoundedIcon />
                            </div>}
            </Box>

        </Box>
    )
}