import { FormikRadioGroup } from 'components/UI/Inputs/FormikInputs'
import { useFormik } from 'formik';
import React from 'react'
import { useStyles } from './styles';
import { Box, Grid } from '@mui/material';
import { FormikTextInput } from 'components/Filters/FormikInputs';
import * as yup from "yup";
import { Button } from 'components';
import { MerchantBOPIUpd } from 'services/hooli-services/MerchantBOPIUpd';
import { userCredentials } from 'recoilState/GlobalState';
import { useRecoilValue } from 'recoil';

export const ParticularidadesImpositivas = ({ merchantInfo, load, setLoad, setModalDetails, modalDetails, location }) => {
    const credentials = useRecoilValue(userCredentials);
    const styles = useStyles();
    let patternTwoDigisAfterComma = /^\d+(\.\d{0,2})?$/;
    const handleUpd = async (values) => {
        setLoad(true)
        const response = await MerchantBOPIUpd(credentials, {
            merchantId: merchantInfo.merchantId,
            merchantRetieneIVA: values.iva === "true" || values.iva === true ? "Y" : "N",
            merchantRetieneIG: values.ganancias === "true" || values.ganancias === true ? "Y" : "N",
            merchantRetieneIB: values.ingresosBrutos === "true" || values.ingresosBrutos === true ? "Y" : "N",
            merchantRetieneIBAlicuota: Number(values.alicuota),
            merchantCombustible: values.combustible === "true" || values.combustible === true ? "Y" : "N",
            merchantMicroempresa: values.microempresas === "true" || values.microempresas === true ? "Y" : "N",
            estadoRegistroCod: "A"
        }, location)
        setLoad(false)
        setModalDetails({ ...modalDetails, isOpen: false })
    }
    const handleDecline = async () => {
        setLoad(true)
        const response = await MerchantBOPIUpd(credentials, {
            merchantId: merchantInfo.merchantId,
            merchantRetieneIVA: formik.values.iva === "true" || formik.values.iva === true ? "Y" : "N",
            merchantRetieneIG: formik.values.ganancias === "true" || formik.values.ganancias === true ? "Y" : "N",
            merchantRetieneIB: formik.values.ingresosBrutos === "true" || formik.values.ingresosBrutos === true ? "Y" : "N",
            merchantRetieneIBAlicuota: Number(formik.values.alicuota),
            merchantCombustible: formik.values.combustible === "true" || formik.values.combustible === true ? "Y" : "N",
            merchantMicroempresa: formik.values.microempresas === "true" || formik.values.microempresas === true ? "Y" : "N",
            estadoRegistroCod: "R"
        }, location)
        setLoad(false)
        setModalDetails({ ...modalDetails, isOpen: false })
    }
    const validationSchema = yup.object({
        iva: yup.boolean().required("Debes seleccionar una opción"),
        ganancias: yup.boolean().required("Debes seleccionar una opción"),
        ingresosBrutos: yup.boolean().required("Debes seleccionar una opción"),
        alicuota: yup.number("Debe ser un numero").nullable(true).when("ingresosBrutos", {
            is: true,
            then: yup.number("Debe ser un numero").test(
                "is-decimal",
                "Debe ingresar maximo dos decimales",
                (val) => {
                    if (val != undefined) {
                        return patternTwoDigisAfterComma.test(val);
                    }
                    return true;
                }
            ).max(100, "Debe ser un valor menor a 100").min(0.01, "Debe ser mayor a 0").typeError("Debe ser un numero con separador decimal punto").nullable(true).required("Debes ingresar una alicuota")
        }),
        combustible: yup.boolean().required("Debes seleccionar una opción"),
        microempresas: yup.boolean().required("Debes seleccionar una opción"),
    })
    const formik = useFormik({
        initialValues: {
            iva: merchantInfo.merchantRetieneIVA === "Y" ? true : false,
            ganancias: merchantInfo.merchantRetieneIG === "Y" ? true : false,
            ingresosBrutos: merchantInfo.merchantRetieneIB === "Y" ? true : false,
            alicuota: merchantInfo.merchantRetieneIBAlicuota,
            combustible: merchantInfo.merchantCombustible === "Y" ? true : false,
            microempresas: merchantInfo.merchantMicroempresa === "Y" ? true : false,
        },
        onSubmit: (values) => {
            console.log(values, "values")
            handleUpd(values)
        },
        validationSchema: validationSchema,
        initialErrors: {
            merchantRetieneIVA: false,
            merchantRetieneIG: false,
            merchantRetieneIB: false,
            merchantRetieneIBAlicuota: false,
            merchantCombustible: false,
            merchantMicroempresa: false,
        }
    });
    const dataFormik = {
        iva: { label: "¿Retiene IVA?*" },
        ganancias: { label: "¿Retiene Impuestos a las ganancias?*" },
        ingresosBrutos: { label: "¿Retiene Ingresos Brutos?*" },
        alicuota: { label: "Alícuota de retención de Ingresos Brutos" },
        combustible: { label: "Régimen de combustible*" },
        microempresas: { label: "Régimen de Microempresas*" }
    }
    return (
        <>
            <Grid container width={"100%"} padding={"20px 0 50px 0"}>
                <Grid item width={"100%"} display={"flex"} gap={4}>
                    <Box display={"flex"} flexDirection={"column"} width={"50%"} className={`${styles.containerInput} ${styles.containerRadio}`}>
                        <FormikRadioGroup name={"iva"} formik={formik} label={dataFormik.iva.label} />
                    </Box>
                    <Box display={"flex"} flexDirection={"column"} width={"50%"} className={`${styles.containerInput} ${styles.containerRadio}`}>
                        <FormikRadioGroup name={"ganancias"} formik={formik} label={dataFormik.ganancias.label} />
                    </Box>
                </Grid>
                <Grid item width={"100%"} display={"flex"} gap={4}>
                    <Box display={"flex"} flexDirection={"column"} width={"50%"} className={`${styles.containerInput} ${styles.containerRadio}`}>
                        <FormikRadioGroup name={"ingresosBrutos"} formik={formik} label={dataFormik.ingresosBrutos.label} />
                    </Box>
                    <Box display={"flex"} flexDirection={"column"} width={"50%"} className={styles.containerInput}>
                        <FormikTextInput data={dataFormik} name={"alicuota"} formik={formik} variant="standard" disabled={formik.values.ingresosBrutos === "" || formik.values.ingresosBrutos === false} />
                    </Box>
                </Grid>
                <Grid item width={"100%"} display={"flex"} gap={4}>
                    <Box display={"flex"} flexDirection={"column"} width={"50%"} className={`${styles.containerInput} ${styles.containerRadio}`}>
                        <FormikRadioGroup name={"combustible"} formik={formik} label={dataFormik.combustible.label} />
                    </Box>
                    <Box display={"flex"} flexDirection={"column"} width={"50%"} className={`${styles.containerInput} ${styles.containerRadio}`}>
                        <FormikRadioGroup name={"microempresas"} formik={formik} label={dataFormik.microempresas.label} />
                    </Box>
                </Grid>
            </Grid>
            <div>
                <Button text={"Confirmar"} onClick={() => formik.handleSubmit()} disabled={load} />
                <Button text={"Rechazar"} onClick={() => handleDecline()} disabled={load} />
                <Button text={"Cerrar"} onClick={() => setModalDetails({ ...modalDetails, confirmationModalOpen: true })} />
            </div>
        </>
    )
}
