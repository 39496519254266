import { React } from "react";

import { List, ListItem, ListItemIcon } from "@mui/material";



import { Routes } from "consts/Routes-App";


import styles from "./ListDrawer.module.scss";
import { HomeRounded } from "@mui/icons-material";
import { Link } from "react-router-dom";



export default function ListDrawer({ setOpen }) {


    const handleClick = () => {
        setOpen(false);
    };

    return (
        <>

            <List
                aria-labelledby="nested-list-subheader"
                className={styles.root}
                component="nav"
            >
                <Link to={Routes.dashboard.route}>
                    <ListItem
                        className={styles.itemList}
                        onClick={handleClick}
                    >
                        <ListItemIcon>
                            <HomeRounded className={styles.iconsDrawer} />
                        </ListItemIcon>
                        <span className={styles.textColor}>Home</span>
                    </ListItem>
                </Link>
                <Link to={Routes.comercios.route}>
                    <ListItem
                        className={styles.itemList}
                        onClick={handleClick}
                    >
                        <ListItemIcon>
                            <HomeRounded className={styles.iconsDrawer} />
                        </ListItemIcon>
                        <span className={styles.textColor}>Comercios</span>
                    </ListItem>
                </Link>

            </List>

        </>
    );
}
