import axiosPost from "./axiosPost";

export const MerchantBOPIUpd= async (credentials, data, location) => {
    const body = {
        service: "MerchantBOPIUpd",
        params: {
            merchantId: data.merchantId,
            merchantRetieneIVA: data.merchantRetieneIVA,
            merchantRetieneIG: data.merchantRetieneIG,
            merchantRetieneIB: data.merchantRetieneIB,
            merchantRetieneIBAlicuota: data.merchantRetieneIBAlicuota,
            merchantCombustible: data.merchantCombustible,
            merchantMicroempresa: data.merchantMicroempresa,
            estadoRegistroCod: data.estadoRegistroCod,
            registroLatitud: location.latitud,
            registroLongitud: location.longitud,
        },
    };    
    try {
        
        const response = await axiosPost("Y", body, credentials)
        return response;
    } catch (err) {
        console.error(`Error: ${err}`);
    }
}