export const comercios = [
    "CUIT",
    "Razon Social",
    "Nombre fantasia",
    "PF/PJ",
    "Fecha registro",
    "Origen",
    "Estado operativo",
    "Estado registro",
    "Responsable",
    "Fecha",
];

export const apoderados = ["Documentos"];
