import axiosPost from "./axiosPost";

export const MerchantBONotaUpd= async (credentials, data) => {
    const body = {
        service: "MerchantBONotaUpd",
        params: {
            merchantNotaMerchantId: data.merchantNotaMerchantId,
            merchantNotasTexto: data.merchantNotasTexto,
        },
    };    
    try {
        
        const response = await axiosPost("Y", body, credentials)
        return response;
    } catch (err) {
        console.error(`Error: ${err}`);
    }
}