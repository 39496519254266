import React, { useState } from 'react'
import { useStyles } from './styles';
import { FormikDateInput, FormikTextInput } from 'components/Filters/FormikInputs';
import { useFormik } from 'formik';
import * as yup from "yup";
import { userCredentials } from 'recoilState/GlobalState';
import { useRecoilValue } from 'recoil';
import { Box, Grid } from '@mui/material';
import { DropZone } from 'components/DropZone';
import { Button } from 'components';
import { MerchantBOFilesDo } from 'services/hooli-services/MerchantBoFilesDo';
import axios from 'axios';
import { DocumentosPut } from 'services/hooli-services/DocumentosPut';
import { MerchantBOCSUpd } from 'services/hooli-services/MerchantBOCSUpd';

export const ContratoSocial = ({ merchantInfo, load, setLoad, setModalDetails, modalDetails, location }) => {
    const styles = useStyles();
    const credentials = useRecoilValue(userCredentials);
    const [updFile, setUpdFile] = useState(false);
    const [file, setFile] = useState(null)
    const handleUpd = async (values) => {
        setLoad(true)
        const response = await MerchantBOCSUpd(credentials, {
            merchantId: merchantInfo.merchantId,
            merchantInscripcionFecha: values.fechaInscripcion,
            merchantInscripcionNumero: values.numeroInscripcion,
            estadoRegistroCod: "A"
        }, location)
        setLoad(false)
        setModalDetails({ ...modalDetails, isOpen: false })
    }
    const handleDecline = async () => {
        setLoad(true)
        const response = await MerchantBOCSUpd(credentials, {
            merchantId: merchantInfo.merchantId,
            merchantInscripcionFecha: formik.values.fechaInscripcion,
            merchantInscripcionNumero: formik.values.numeroInscripcion,
            estadoRegistroCod: "R"
        }, location)
        setLoad(false)
        setModalDetails({ ...modalDetails, isOpen: false })
    }
    const handleReadFile = async () => {
        const data = {
            fileName: `${merchantInfo.merchantCUIT.replaceAll('-', '')}_CS.pdf`,
            code: "CS",
            cuit: merchantInfo.merchantCUIT.replaceAll('-', ''),
            action: "R"
        }
        const response = await MerchantBOFilesDo(credentials, data)
        if (response.status.code === 1 && response.result[0].url) {
            const { data } = await axios.get(response.result[0].url, {
                headers: {
                    'Accept': 'application/pdf',
                    "Content-Type": "application/octet-stream"
                },
                responseType: "blob",
                data: {}
            })
            let urlBlob = window.URL.createObjectURL(new Blob([data]));
            let a = document.createElement('a');
            a.href = urlBlob;
            a.download = `${merchantInfo.merchantCUIT.replaceAll('-', '')}CS.pdf`; // Nombre del archivo
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
        }
    }

    const handleUpdFile = async () => {
        const data = {
            fileName: `${merchantInfo.merchantCUIT.replaceAll('-', '')}_CS.pdf`,
            code: "CS",
            cuit: merchantInfo.merchantCUIT.replaceAll('-', ''),
            action: "U"
        }
        const response = await MerchantBOFilesDo(credentials, data)
        if (response.status.code === 1) {
            // console.log("File updated", file[0])
            await DocumentosPut(response.result[0].url, file[0])
            setUpdFile(false)
            setFile(null)
        }
    }
    function formatDate(date) {
        return new Date(date).toLocaleDateString()
    }
    const validationSchema = yup.object({
        fechaInscripcion: yup.date().max(new Date(), 'La fecha debe ser igual o anterior a hoy').required("Debes ingresar una fecha"),
        numeroInscripcion: yup.string().required("Debes ingresar un numero de inscripción"),
    });
    const dataFormik = {
        fechaInscripcion: { label: "Fecha de inscripción en el registro*", placeholder: "1234" },
        numeroInscripcion: { label: "Número de inscripción en el registro*", placeholder: "1234" },
    }
    const formik = useFormik({
        initialValues: {
            fechaInscripcion: merchantInfo.merchantInscripcionFecha || "",
            numeroInscripcion: merchantInfo.merchantInscripcionNumero || "",
        },
        validationSchema: validationSchema,
        onSubmit: (values) => {
            console.log(values, "values")
            handleUpd(values)
        }
    });
    return (
        <>
            {updFile ? <div style={{ display: "flex", flexDirection: "column", gap: "8px", alignItems: "center" }}>
                <DropZone file={file} setFile={setFile} />
                <Grid item display={"flex"} flexDirection={"row"} justifyContent={"center"} alignItems={"center"} width={"100%"}>
                    <Button text={"Subir"} onClick={handleUpdFile} />
                    <Button text={"Cancelar"} onClick={() => setUpdFile(false)} />
                </Grid>
            </div> : <div style={{ display: "flex", flexDirection: "column", gap: "20px" }}>
                <Box display={"flex"} width={"100%"} gap={4} className={styles.containerInput}>
                    <FormikDateInput data={dataFormik} name={"fechaInscripcion"} formik={formik} />
                    <FormikTextInput data={dataFormik} name={"numeroInscripcion"} formik={formik} />
                </Box>
                <Grid item display={"flex"} flexDirection={"row"} justifyContent={"center"} alignItems={"center"} width={"100%"}>
                    <Button text={"Descargar Contrato social"} onClick={handleReadFile} />
                    <Button text={"Subir Contrato social"} onClick={() => setUpdFile(true)} />
                </Grid>
            </div>}
            <div>
                <Button text={"Confirmar"} onClick={formik.handleSubmit} disabled={load} />
                <Button text={"Rechazar"} onClick={() => handleDecline()} disabled={load} />
                <Button text={"Cerrar"} onClick={() => setModalDetails({ ...modalDetails, confirmationModalOpen: true })} />
            </div>
        </>
    )
}
