import axiosPost from "./axiosPost";

export const MerchantBOFilesDo= async (credentials, data) => {
    const body = {
        service: "MerchantBOFilesDo",
        params: {
            fileName: data.fileName,
            code: data.code,
            cuit: data.cuit,
            beneficiarioNroDeDocumento: data.beneficiarioNroDeDocumento,
            action: data.action,
        },
    };    
    try {
        
        const response = await axiosPost("Y", body, credentials)
        return response;
    } catch (err) {
        console.error(`Error: ${err}`);
    }
}