import axiosPost from './axiosPost';

export const ProvinciasGet = async (credentials) => {
    const body = {
        "service": "ProvinciaGet",
        "params": { }
    };

    try {
        const result = await axiosPost('Y', body, credentials)
       
        return result
    } catch (err) {
        console.log(err)
    }
};