import React from 'react';
import { Box, Grid } from '@mui/material';
import { FormikTextInput } from "../../../../../components/Filters/FormikInputs";
import { useStyles } from './styles';


function FormContacto({ formik }) {

    const styles = useStyles();

    const dataFormik = {
        mail: { label: "Mail*", placeholder: "Comercio@gmail.com" },
        telefono: { label: "Teléfono", placeholder: "45678909" },
        celular: { label: "Celular*", placeholder: "1523456789" },
        sitioWeb: { label: "Sitio Web", placeholder: "www.Comercio.com" },
        instagram: { label: "Instagram", placeholder: "Comercio.ok" },
        facebook: { label: "Facebook", placeholder: "Comercio" }

    }
    return (
        <Grid width={"100%"} padding={"20px 0 50px 0"}>
            <Box display={"flex"} width={"100%"} gap={4} className={styles.containerInput}>
                <FormikTextInput data={dataFormik} name={"mail"} formik={formik} />
                <FormikTextInput data={dataFormik} name={"telefono"} formik={formik} />
            </Box>
            <Box display={"flex"} width={"100%"} gap={4} className={styles.containerInput}>
                <FormikTextInput data={dataFormik} name={"celular"} formik={formik} />
                <FormikTextInput data={dataFormik} name={"sitioWeb"} formik={formik} />
            </Box>
            <Box display={"flex"} width={"100%"} gap={4} className={styles.containerInput}>
                <FormikTextInput data={dataFormik} name={"instagram"} formik={formik} />
                <FormikTextInput data={dataFormik} name={"facebook"} formik={formik} />
            </Box>
        </Grid>

    )
}

export { FormContacto }
