import React from 'react';
import { FormContacto } from '../form';
import { useFormik } from 'formik';
import * as yup from "yup";
import { Button } from 'components';
import { MerchantBOContactoUpd } from 'services/hooli-services/MerchantBOContactoUpd';
import { userCredentials } from 'recoilState/GlobalState';
import { useRecoilValue } from 'recoil';


function ContainerContacto({ merchantInfo, load, setLoad, setModalDetails, modalDetails, location }) {
    const credentials = useRecoilValue(userCredentials);
    const validationSchema = yup.object({
        mail: yup.string().required("Debes ingresar tu mail").matches(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g, "Debe ingresar un formato correcto"),
        celular: yup.string().required("Debes ingresar tu celular")
    });
    const handleUpd = async (values) => {
        setLoad(true)
        const response = await MerchantBOContactoUpd(credentials, {
            merchantId: merchantInfo.merchantId,
            merchantMail: values.mail,
            merchantTelefono: values.telefono,
            merchantCelular: values.celular,
            merchantWebSite: values.sitioWeb,
            merchantInstagram: values.instagram,
            merchantFacebook: values.facebook,
            estadoRegistroCod: "A"
        }, location)
        setLoad(false)
        setModalDetails({ ...modalDetails, isOpen: false })
    }
    const handleDecline = async () => {
        setLoad(true)
        const response = await MerchantBOContactoUpd(credentials, {
            merchantId: merchantInfo.merchantId,
            merchantMail: formik.values.mail,
            merchantTelefono: formik.values.telefono,
            merchantCelular: formik.values.celular,
            merchantWebSite: formik.values.sitioWeb,
            merchantInstagram: formik.values.instagram,
            merchantFacebook: formik.values.facebook,
            estadoRegistroCod: "R"
        }, location)
        setLoad(false)
        setModalDetails({ ...modalDetails, isOpen: false })
    }
    const formik = useFormik({
        initialValues: {
            mail: merchantInfo.merchantMail,
            telefono: merchantInfo.merchantTelefono,
            celular: merchantInfo.merchantCelular,
            sitioWeb: merchantInfo.merchantWebSite,
            instagram: merchantInfo.merchantInstragram,
            facebook: merchantInfo.merchantFacebook
        },
        validationSchema: validationSchema,
        onSubmit: (values) => {
            handleUpd(values)
        },
        initialErrors: {
            mail: false,
            celular: false
        }
    });
    return (
        <>
            <FormContacto formik={formik} />
            <div>
                <Button text={"Confirmar"} onClick={() => formik.handleSubmit()} disabled={load} />
                <Button text={"Rechazar"} onClick={() => handleDecline()} disabled={load} />
                <Button text={"Cerrar"} onClick={() => setModalDetails({ ...modalDetails, confirmationModalOpen: true })} />
            </div>
        </>

    )
}

export { ContainerContacto }