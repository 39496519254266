import axiosPost from "./axiosPost";

export const MerchantBOCSUpd= async (credentials, data, location) => {
    const body = {
        service: "MerchantBOCSUpd",
        params: {
            merchantId: data.merchantId,
            merchantInscripcionFecha: data.merchantInscripcionFecha,
            merchantInscripcionNumero: data.merchantInscripcionNumero,
            estadoRegistroCod: data.estadoRegistroCod,
            registroLatitud: location.latitud,
            registroLongitud: location.longitud,
        },
    };    
    try {
        
        const response = await axiosPost("Y", body, credentials)
        return response;
    } catch (err) {
        console.error(`Error: ${err}`);
    }
}