import { FALLBACK_URL } from "consts/env";
import React, { useEffect } from "react";
import { Outlet } from "react-router-dom";

const ProtectedRoute = ({
    isAuth
}) => {

    useEffect(() => {
        if (!isAuth) {
            return window.location.assign(FALLBACK_URL);
        }
    }, [isAuth]);

    if (isAuth) return <Outlet />;

}

export default ProtectedRoute;
